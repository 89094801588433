<div class="content-frame">
    <div class="container custom-box">
        <div class="row">
            <div class="col-lg">
                <h4>
                    <em class="fas fa-chevron-left f-20 pointer pr-2 pt-1 pb-2 title"
                        (click)="back()">
                    </em>
                    <span class="pt-3 title">
                        {{ (contractId?'CONTRACT.Edit Contract Group': 'CONTRACT.Create Contract Group') | translate }}
                    </span>
                </h4>
            </div>
        </div>

        <div class="row no-gutters pb-4">
            <div class="step col-12 mt-3 col d-grid gap-10">
                <label for="required"
                    class="mb-0">
                    {{'CONTRACT.Contract Group Name' | translate}}
                </label>
                <input type="text"
                    [(ngModel)]="contractName"
                    class="form-control"
                    [placeholder]="'CONTRACT.Contract Group Name'|translate">
            </div>
            <div class="step col-12 mt-4 col d-grid gap-10 custom-address">
                <label class="mb-0">
                    {{'CONTRACT.User' | translate}}
                </label>
                <ng-select [items]="people$ | async"
                    *ngIf="!refreshDropdown"
                    class="custom"
                    [loading]="peopleLoading"
                    [bindLabel]="translate.currentLang ==='en'? 'label' : 'context.name_th'"
                    [disabled]="formDisable"
                    placeholder="{{ 'MEMOS.SELECT' | translate }}"
                    [typeahead]="peopleInput$"
                    [minTermLength]="2"
                    [typeToSearchText]="'user.type-search' | translate"
                    [multiple]="true"
                    [notFoundText]="'SELECT.No items found' | translate"
                    [(ngModel)]="selectedPeople">
                </ng-select>

            </div>
            <div class="w-100 d-flex justify-content-end mt-2">
                <div class="col-auto my-3 p-0">
                    <button class="btn btn-outline eh-button w-custom"
                        type="button"
                        (click)="back()"
                        [style.--c]="themeList?.header_table_color">
                        {{"MEMOS.CANCEL" | translate}}</button>
                </div>
                <div class="col-auto my-3 p-0 pl-3">
                    <button class="btn eh-button w-custom"
                        type="button"
                        appSetTheme
                        (click)="clickSubmit(confirmationModal)"
                        type="button"
                        [disabled]="!contractName"
                        [ladda]="isLoading">
                        {{ 'MEMOS.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmationModal
    let-c="close"
    let-d="dismiss">
    <div class="modal-header red">
        <h4 class="modal-title">
            {{ (actionType === 'delete' ? "MEMOS.CONFIRM-DELETE" :
            "BANNER.Edit Confirmation") |translate}}
        </h4>
        <button type="button"
            class="close"
            aria-label="Close"
            (click)="c('Cross click')">
            <i class="fal fa-times"></i>
        </button>
    </div>
    <div class="modal-body text-center">
        <h4>{{"ADDRESS-BOOK.Are you sure you want to edit?"| translate}}
        </h4>
    </div>
    <div class="modal-footer">
        <button class="btn p-btn btn-outline"
            [style.--c]="themeList?.header_table_color"
            (click)="c()">
            {{'MEMOS.CANCEL'|translate}}
        </button>
        <button class="btn p-btn ml-2"
            type="button"
            appSetTheme
            (click)="editAddressBook()">{{"DEPARTMENT.CONFIRM" | translate}}</button>
    </div>
</ng-template>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { AdfsCallbackComponent } from './core/components/adfs-callback/adfs-callback.component';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { Component } from '@angular/core';
import { TermsPageComponent } from './core/components/terms-page/terms-page.component';
import { SsoCallbackComponent } from './core/components/sso-callback/sso-callback.component';
import { CloudServiceComponent } from './core/components/cloud-service/cloud-service.component';

// This component will be used in function 'openMediaUrlInNewWindow'
//   because mobile device cannot preview pdf and it will automatically download file.
@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
})
class MediaWindowComponent {}

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    data: [
      'General',
      'Broadcast',
      'IT',
      'Master Admin',
      'Super Admin',
      'Company Admin',
    ],
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/sso/callback',
    component: SsoCallbackComponent,
  },
  {
    path: 'cloud-service',
    component: CloudServiceComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsPageComponent,
  },
  {
    path: 'adfs-callback',
    component: AdfsCallbackComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    data: [
      'General',
      'Broadcast',
      'IT',
      'Master Admin',
      'Super Admin',
      'Company Admin',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'configuration',
    data: ['Master Admin', 'Super Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: ['Master Admin', 'Super Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'department',
    data: ['Master Admin', 'Super Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'manage-csr',
    data: ['Master Admin', 'Super Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/manage-csr/manage-csr.module').then(
        (m) => m.ManageCsrModule,
      ),
  },
  {
    path: 'kpi-dashboard',
    data: ['Master Admin', 'Super Admin', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'usage-dashboard',
    data: ['Super Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/usage/usage.module').then(
        (m) => m.UsageModule,
      ),
  },
  {
    path: 'memo-report',
    data: ['Super Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'operation-log',
    data: ['Super Admin', 'Master Admin', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'pending-approval',
    data: ['General', 'Broadcast', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/executive/executive.module').then(
        (m) => m.ExecutiveModule,
      ),
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: 'memos',
    data: ['General', 'Broadcast', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/memos/memo.module').then((m) => m.MemoModule),
  },
  {
    path: 'address-book',
    data: ['Master Admin', 'Super Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/address-book/address-book.module').then(
        (m) => m.AddressBookModule,
      ),
  },
  {
    path: 'contract-group',
    data: ['Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/contract-group/contract-group.module'
      ).then((m) => m.ContractGroupModule),
  },
  {
    path: 'certificate',
    data: ['Master Admin', 'Super Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  {
    path: 'activate-account',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'broadcast',
    data: [
      'General',
      'Broadcast',
      'Super Admin',
      'Master Admin',
      'Company Admin',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/broadcast/broadcast.module').then(
        (m) => m.BroadcastModule,
      ),
  },
  {
    path: 'email-group',
    data: ['General', 'Super Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/email-group/email-group.module').then(
        (m) => m.EmailGroupModule,
      ),
  },
  {
    path: 'company-list',
    data: ['Super Admin', 'Master Admin', 'IT', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/company/company.module').then(
        (m) => m.CompanyModule,
      ),
  },
  {
    path: 'manage-document',
    data: ['Super Admin', 'Master Admin', 'IT', 'Company Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-document/manage-document.module'
      ).then((m) => m.ManageDocumentModule),
  },
  { path: 'website-maintenance', component: MaintenanceComponent },

  // otherwise redirect to home
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { User } from '../../../shared/models/user.models';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebsocketMessage } from './navbar.model';
import { ApiService } from '../../http/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  navBarNameList: { url: string; name: string }[] = [
    { url: 'news-feed', name: 'FEED.NEWS-FEED' },
    { url: 'users', name: 'SIDEBAR.MANAGE-USERS' },
    { url: 'department/add', name: 'SIDEBAR.DEPARTMENT' },
    { url: 'department/level', name: 'SIDEBAR.DEPARTMENT-LEVEL' },
    { url: 'kpi-dashboard', name: 'SIDEBAR.DASHBOARD' },
    { url: 'configuration', name: 'SIDEBAR.CONFIGURATION' },
    { url: 'cc-group', name: 'SIDEBAR.MANAGE-CC-GROUP' },
    { url: 'certificate', name: 'SIDEBAR.CERTIFICATE' },
    {
      url: 'manage-types-upload-memo/type',
      name: 'MEMO-UPLOAD-TYPE.MEMO-UPLOAD-TYPE',
    },
    {
      url: 'manage-types-upload-memo/template',
      name: 'MEMO-UPLOAD-TEMPLATE.MEMO-UPLOAD-TEMPLATE',
    },
    { url: 'loa', name: 'LOA.LINE-OF-APPROVAL' },
    { url: 'memos', name: 'SIDEBAR.MEMOS' },
    { url: 'approval', name: 'SIDEBAR.PENDING' },
    { url: 'operation-log', name: 'SIDEBAR.OPERATION-LOG' },
    { url: 'memo-report', name: 'SIDEBAR.MEMO-REPORT' },
    { url: 'profile-detail', name: 'PROFILE.PROFILE' },
  ];
  private setnavbar = new BehaviorSubject<boolean>(false);
  private navbarTitle = new BehaviorSubject<string>('News feed');
  // tslint:disable-next-line:variable-name
  private _websocketSubject: WebSocketSubject<WebsocketMessage>;
  public websocketSubject = new Subject<WebsocketMessage>();
  // private websocketUrl = environment.websocketUrl;

  data = this.setnavbar.asObservable();

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
  ) {
    this.authenticationService.currentUser.subscribe((newValue) => {
      if (newValue) {
        // this.connectToWebsocket(newValue);
      }
    });
  }

  getNavbarTitle() {
    return this.navbarTitle;
  }

  setActiveSidebar(active: boolean) {
    this.setnavbar.next(active);
  }

  setNavbarTitle(title: string) {
    this.navbarTitle.next(title);
  }

  private connectToWebsocket(token: User): void {
    if (this._websocketSubject) {
      // unsubscribe old value
      this._websocketSubject.unsubscribe();
    }
    // this._websocketSubject = webSocket(
    //   this.websocketUrl + '?uid=' + token.access
    // );
    this._websocketSubject.subscribe(
      (value) => {
        this.websocketSubject.next(value);
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete'), // Called when connection is closed (for whatever reason).
    );
  }

  downloadFileUrl(url) {
    const setUrl = environment.baseUrl + '/' + url;
    this.apiService.openDownloadFile(setUrl, 'transfer');
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ContractGroupService } from '../contract-group.service';
import {
  DropdownResponse,
  DropdownService,
} from 'src/app/shared/service/dropdown.service';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-create-contract-group',
  templateUrl: './create-contract-group.component.html',
  styleUrls: ['./create-contract-group.component.scss'],
})
export class CreateContractGroupComponent
  implements OnInit, OnDestroy
{
  themeList: ThemeList;
  subscription: Subscription[] = [];
  contractName: string;
  isLoading = false;
  user: any[] = [];

  tenants = null;
  contractId;
  modal: NgbModalRef;
  userList;
  people$: Observable<{ label: string; value: number }[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  selectedPeopleList: {
    value: number;
    label: string;
    context: { name_th: string };
  }[];
  selectedPeople;
  refreshDropdown = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private activateRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private store: Store,
    private alert: AlertService,
    protected modalService: NgbModal,
    private contractService: ContractGroupService,
    private dropdownService: DropdownService,
  ) {
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateSelect();
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  ngOnInit(): void {
    this.initPeoplePipe();
    this.activateRoute.params.subscribe((res: { id: string }) => {
      if (res.id) {
        this.contractId = res.id;
        this.getContract();
      }
    });
  }

  translateSelect(): void {
    this.refreshDropdown = true;
    setTimeout(() => {
      this.refreshDropdown = false;
    }, 1);
  }

  initPeoplePipe(): void {
    this.people$ = concat(
      of(this.selectedPeopleList),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params = {
            type: 'people',
            group: 'General|Broadcast|Company Admin',
            query,
            company_filter_type: 'company_domain',
          };

          return this.dropdownService.getDropdown(params).pipe(
            map((dropdown: DropdownResponse) => {
              dropdown.people.forEach((item) => {
                if (
                  !item.context.name_th.trim() ||
                  !item.context.name_th
                ) {
                  item.context.name_th = item.label;
                }
                item.label =
                  item.label + ' <' + item.context.email + '>';
                item.context.name_th =
                  item.context.name_th +
                  ' <' +
                  item.context.email +
                  '>';
              });
              return dropdown.people;
            }),
          );
        }),
        catchError(() => of([])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  getContract(): void {
    this.spinner.show();
    this.contractService.getContractById(this.contractId).subscribe(
      (res) => {
        this.contractId = res?.id || null;
        this.contractName = res?.name || '';
        this.selectedPeople = res.members.map((item) => {
          return {
            value: item.id,
            label: item.full_name
              ? item.full_name + ' <' + item.email + '>'
              : item.full_name_th + ' <' + item.email + '>',
            context: {
              name_th: item.full_name_th
                ? item.full_name_th + ' <' + item.email + '>'
                : item.full_name + ' <' + item.email + '>',
            },
          };
        });
        this.initPeoplePipe();
        this.spinner.hide();
      },
      (error) => {
        this.store.dispatch(new ErrorNotification(error));
        this.spinner.hide();
      },
    );
  }
  back(): void {
    this.router.navigate(['contract-group']);
  }

  clickSubmit(content): void {
    if (this.contractId) {
      this.modalService.open(content, {
        backdrop: 'static',
        centered: true,
        size: 'md',
      });
    } else {
      this.createAddressBook();
    }
  }

  createAddressBook(): void {
    this.isLoading = true;
    const member = [];
    this.selectedPeople?.forEach((item: any) => {
      member.push(item.value);
    });
    const data = {
      name: this.contractName,
      members: member,
    };
    this.contractService.createContract(data).subscribe(
      (res) => {
        this.alert.success(
          this.translate.instant(
            'CONTRACT.Contact group created successfully',
          ),
        );
        this.isLoading = false;
        this.back();
      },
      (error) => {
        this.isLoading = false;
        if (error.error && error.error.name) {
          // const convertError = error.error.join();
          const non_field_errors: string[] = error.error.name;
          const convertError = non_field_errors.join('\n');
          if (convertError === 'ชื่อนี้มีอยู่แล้วในระบบ') {
            this.alert.error(
              convertError.replace(
                'ชื่อนี้มีอยู่แล้วในระบบ',
                this.translate.instant('COMPANY.ERROR-DUPLICATE'),
              ),
            );
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
        } else {
          this.store.dispatch(new ErrorNotification(error));
        }
        // const convertError = error.error.join();
        // if (convertError === 'Name is already in used') {
        //   this.alert.error(
        //     convertError.replace(
        //       'Name is already in used',
        //       this.translate.instant('CONTRACT.ERROR-DUPLICATE'),
        //     ),
        //   );
        // } else {
        this.store.dispatch(new ErrorNotification(error));
        // }
      },
    );
  }

  editAddressBook(): void {
    this.isLoading = true;
    const member = [];
    this.selectedPeople.forEach((item: any) => {
      member.push(item.value);
    });
    const data = {
      name: this.contractName,
      members: member,
    };
    this.contractService
      .editContract(data, this.contractId)
      .subscribe(
        (res) => {
          this.modalService.dismissAll();
          this.alert.success(
            this.translate.instant('MEMOS.SAVE-CHANGES'),
          );
          this.isLoading = false;
          this.back();
        },
        (error) => {
          this.modalService.dismissAll();
          this.isLoading = false;
          if (error.error && error.error.name) {
            // const convertError = error.error.join();
            const non_field_errors: string = error.error.name;
            const convertError = non_field_errors;
            if (convertError === 'ชื่อนี้มีอยู่แล้วในระบบ') {
              this.alert.error(
                convertError.replace(
                  'ชื่อนี้มีอยู่แล้วในระบบ',
                  this.translate.instant('CONTRACT.ERROR-DUPLICATE'),
                ),
              );
            } else {
              this.store.dispatch(new ErrorNotification(error));
            }
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
        },
      );
  }
}

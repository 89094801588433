<div class="row">
  <div class="col-12 text-center"
      *ngIf="sendingTaskDetail._state === celeryImportState.Success">
    <h5 *ngIf="!isResponseData">{{ (resultTitle || ('REQUEST.SUCCESSFULLY' | translate)) | titlecase }}</h5>
    <h5 *ngIf="isResponseData">{{ (resultTitle || ('REQUEST.UPLOAD-SUCCESSFUL' | translate)) | titlecase }}</h5>
  </div>
  <div class="col-12 text-center"
      *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
    <h5 *ngIf="isResponseData">{{ (resultTitle || ('REQUEST.UPLOAD-FAILED' | translate)) | titlecase }}</h5>
  </div>
  <div class="col-12">
    <ngb-progressbar [type]="currentStateProgressType"
        *ngIf="!isApprove"
        [striped]="currentStateProgressType === 'info'"
        [animated]="currentStateProgressType === 'info'"
        [value]="(sendingTaskDetail.current_row * 100.0 / sendingTaskDetail.total_row) || 100"
        height="2rem">
      <div class="font-weight-bold">
        <h4 class="mb-0"
            *ngIf="sendingTaskDetail.current_row !== null">
          {{ sendingTaskDetail.current_row }} / {{ sendingTaskDetail.total_row }}
        </h4>
        <h4 class="mb-0"
            *ngIf="sendingTaskDetail.current_row === null">{{ sendingTaskDetail._state | titlecase }}</h4>
      </div>
    </ngb-progressbar>

    <ngb-progressbar [type]="currentStateProgressType"
        *ngIf="isApprove"
        [striped]="currentStateProgressType === 'info'"
        [animated]="currentStateProgressType === 'info'"
        [value]="((sendingTaskDetail?.percentage || 0) * 100.0 / 100)"
        height="2rem">
      <div class="font-weight-bold text-right pr-2 progress-approve">
        <h4 class="mb-0 " *ngIf="sendingTaskDetail?.percentage">
          {{ sendingTaskDetail?.percentage || '0' }}%
        </h4>
        <h4 class="mb-0"
            *ngIf="sendingTaskDetail?.percentage === null">{{ sendingTaskDetail._state | titlecase }}</h4>
      </div>
    </ngb-progressbar>
  </div>

  <div class="col-12 mt-4"
      *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
    <ngb-alert type="danger"
        [dismissible]="false">
      <h5 class="text-danger">{{ 'REQUEST.ERROR-OCCURRED' | translate | titlecase }}</h5>
      <div class="frame error p-3 custom-frame mt-3"
          [innerHTML]="sendingTaskDetail.detail"></div>
    </ngb-alert>
  </div>
</div>
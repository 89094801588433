<div *ngIf="!loginError">
  <div
    class="d-flex justify-content-center align-items-center pb-5"
    style="width: 100vw; height: 100vh"
  >
    Connect with CPF SSO ...
  </div>
</div>

<div *ngIf="loginError">
  <div
    class="d-flex justify-content-center align-items-center pb-5"
    style="background-color: #ffffff; width: 100vw; height: 100vh"
  >
    <app-error-card
      [title]="loginErrorText"
      [message]="loginErrorDetail"
      [buttonText]="'LOGIN.BACK-LOGIN' | translate"
      [buttonLink]="'/login'"
    ></app-error-card>
  </div>
</div>

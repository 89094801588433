import { Injectable } from '@angular/core';

declare const gtag: Function; // Google Analytics global function

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  /**
   * Sends an event to Google Analytics using the gtag function.
   * @param eventName Name of the event to be logged.
   * @param eventParams Parameters associated with the event.
   */
  sendEvent(eventName: string, eventParams: { [key: string]: any }) {
    try {
      if (typeof gtag !== 'undefined') {
        // console.log('[GtagService] Event Sent:', eventName, eventParams); // For debugging
        gtag('event', eventName, eventParams); // Sends event to Google Analytics
      } else {
        console.warn('[GtagService] gtag is not defined');
      }
    } catch (error) {
      console.error('[GtagService] Error sending event:', error);
    }
  }
}

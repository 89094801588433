import { Injectable } from '@angular/core';
import { ApiService } from '../core/http/api.service';
import { API_URL } from '../shared/service/api.constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractGroupService {
  constructor(private http: ApiService) {}

  getContractGroup(params) {
    return this.http.get(API_URL.contract_group, params);
  }

  deleteContract(id: number): Observable<any> {
    return this.http.delete(`/api/company-group/${id}/`);
  }
  getContractById(id: number): Observable<any> {
    return this.http.get(`/api/company-group/${id}`);
  }
  createContract(data) {
    return this.http.post(API_URL.contract_group, data);
  }

  editContract(data, id) {
    return this.http.patch(API_URL.contract_group + id + '/', data);
  }
}

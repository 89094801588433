<div class="d-flex align-items-center justify-content-start my-2 old-design"
    *ngIf="!newDesign">
  <div class="mr-2 mt-1 d-none-sm">{{"MEMOS.SHOW" | translate}}</div>
  <div class="mr-2 d-none-sm">
    <ng-select [items]="paginationDropdown"
        class="page-selector custom-pagination mt-1"
        [notFoundText]="'SELECT.No items found'|translate"
        placeholder="{{pageSize}}"
        [clearable]="false"
        (change)="changePageSizeEmit($event)"></ng-select>
  </div>
  <div class="mt-1 d-none-sm">{{"MEMOS.ITEM-PER-PAGE" | translate}}</div>
  <ngb-pagination *ngIf="count"
      [collectionSize]="count"
      [(page)]="page"
      aria-label="count"
      [pageSize]="pageSize"
      [maxSize]="5"
      (pageChange)="changePageEmit()">
    <ng-template ngbPaginationPrevious>{{"PAGINATION.PREV" | translate}}</ng-template>
    <ng-template ngbPaginationNext>{{"PAGINATION.NEXT" | translate}}</ng-template>
  </ngb-pagination>
</div>

<div class="d-md-none d-block"
    [style.--c]="themeList?.header_table_color"
    [style.--t]="themeList?.text_header_table_color"
    *ngIf="isBroadcast && listTypeResendBtn.length > 0">
  <button class="btn btn-outline mt-1 ml-md-2 ml-lg-3"
      (click)="onDownloadMemo()"
      *ngIf="checkStatusApproved">
    <svg-icon src="assets/images/icon_download2.svg"
        class="icon"
        [svgStyle]="{'width.px': 15, 'height.px': 15}"
        [style.--c]="themeList?.header_table_color"></svg-icon>
    {{"BROADCAST.Download all items" | translate}}
  </button>

  <div ngbDropdown
      class="d-inline-block col-12 px-0"
      *ngIf="listTypeResendBtn.length > 0">
    <button class="btn btn-custom-border btn-outline mt-1 ml-md-2 ml-lg-3"
        id="dropdownBasic1"
        ngbDropdownToggle
        (click)="showListMenu = !showListMenu">
      <span *ngIf="listTypeResendBtn[0].status === 'approved'">{{"BROADCAST.btn-download"|translate}}</span>
      <span *ngIf="listTypeResendBtn[0].status === 'pending'">{{"BROADCAST.btn-resend"|translate}}</span>
      <span *ngIf="listTypeResendBtn[0].status === 'terminated'">{{"BROADCAST.btn-resend-again"|translate}}</span>
      <i class="far fa-angle-down ml-2"
          *ngIf="!showListMenu"></i>
      <i class="far fa-angle-up ml-2"
          *ngIf="showListMenu"></i>
    </button>
    <div ngbDropdownMenu
        container="body"
        class="w-100"
        aria-labelledby="dropdownBasic1">
      <ng-container *ngFor="let item of listTypeResendBtn;let i = index;">
        <button ngbDropdownItem
            [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
            class="border-bottom py-2"
            *ngIf="item.status === 'pending'"
            (click)="openModalResend(resent, 'notification');showListMenu = false">
          {{"BROADCAST.btn-resend"|translate}}
        </button>
        <button ngbDropdownItem
            [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
            class="py-2"
            *ngIf="item.status === 'terminated'"
            (click)="openModalResend(resent, 'rejected');showListMenu = false">
          {{"BROADCAST.btn-resend-again"|translate}}
        </button>
        <button ngbDropdownItem
            [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
            class="py-2"
            *ngIf="item.status === 'approved'"
            (click)="onDownloadMemo();showListMenu = false">
          {{"BROADCAST.btn-download"|translate}}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #btnMobile>
  <div class="d-md-none d-block"
      [style.--c]="themeList?.header_table_color"
      [style.--t]="themeList?.text_header_table_color"
      *ngIf="downloadButton && !isBroadcast && !isTemplate">
    <!-- <button class="btn btn-outline ml-md-2 ml-lg-3 mt-1"
      (click)="openModal(selectDateDownloadModal)">asdasd
    <svg-icon src="assets/images/icon_download2.svg"
        class="icon"
        [svgStyle]="{'width.px': 15, 'height.px': 15}"
        [style.--c]="themeList?.header_table_color"></svg-icon>
    {{textBtn? (textBtn|translate):("LOG.DOWNLOAD-CSV" | translate)}}
  </button>
  <button class="btn btn-outline mt-1 ml-1"
      [class.d-none]="!isChecked"
      (click)="onDownloadMemo()"
      [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
    <i class="fa fa-file-alt"
        [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}"></i>
    {{"MEMOS.Download Document" | translate}}
  </button> -->

    <div ngbDropdown
        class="d-inline-block"
        #myDropdown="ngbDropdown"
        container="body"
        [autoClose]="true">
      <button class="btn btn-custom-border btn-outline mt-1 ml-md-2 ml-lg-3 custom-height"
          id="dropdownBasic1"
          ngbDropdownToggle>
        <span><svg-icon src="assets/images/icon_download2.svg"
              class="icon"
              [svgStyle]="{'width.px': 15, 'height.px': 15}"
              [style.--c]="themeList?.header_table_color"></svg-icon></span>
      </button>
      <div ngbDropdownMenu
          class="w-100 py-0"
          aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem
            [ngClass]="!isChecked ? '': 'border-bottom'"
            class="py-2"
            (click)="openModal(selectDateDownloadModal)">
          {{textBtn? (textBtn|translate):("LOG.DOWNLOAD-CSV" | translate)}}
        </button>
        <button ngbDropdownItem
            [class.d-none]="!isChecked"
            class="py-2"
            (click)="onDownloadMemo()">
          {{"MEMOS.Download Document" | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>


<div class="d-sm-block d-md-flex align-items-center justify-content-between my-2 new-design"
    *ngIf="newDesign"
    [style.--c]="themeList?.header_table_color"
    [style.--t]="themeList?.text_header_table_color">
  <div *ngIf="!noneSize"
      class="d-md-flex align-items-center">
    <div class="mr-2 mt-1"
        [ngClass]="isMemosList || isTemplate ||isFolder? 'd-none-sm hide-text-for-ipad': ''">{{"MEMOS.SHOW" |
      translate}}</div>
    <div class="mr-2"
        [ngClass]="isMemosList || isTemplate ||isFolder? 'd-none-sm hide-text-for-ipad': ''">
      <ng-select [items]="paginationDropdown"
          class="mt-1"
          [notFoundText]="'SELECT.No items found'|translate"
          placeholder="{{pageSize}}"
          [clearable]="false"
          (change)="changePageSizeEmit($event)"></ng-select>
    </div>
    <div class="mt-1"
        [ngClass]="isMemosList || isTemplate ||isFolder? 'd-none-sm hide-text-for-ipad': ''">{{"MEMOS.ITEM-PAGE" |
      translate}} {{ count }}
    </div>

    <!-- btn for Mobile -->
    <ng-container *ngTemplateOutlet="btnMobile"></ng-container>
    <ng-container *ngTemplateOutlet="btnForIpadPortrait"></ng-container>
    <ng-container *ngIf="closeNewFeature">
      <button *ngIf="!isTemplate && isChecked"
          type="button"
          appSetTheme
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}"
          class="btn mt-1 ml-2 custom-height d-md-none show-btn-ipad show-btn-mobile"
          [class.d-none]="!isChecked">
        <ng-container *ngTemplateOutlet="moveFolderTemplate;"></ng-container>
      </button>
    </ng-container>
    <ng-container *ngIf="downloadButton && !isBroadcast && !isTemplate">
      <ng-container *ngTemplateOutlet="returnBtnForMobile"></ng-container>
      <button *ngIf="!isTemplate && isChecked && isFolder"
          [style.border-color]="'#dc3545'"
          [style.color]="'#dc3545'"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}"
          class="btn mt-1 ml-2 custom-height d-md-none show-btn-mobile hide-text-for-ipad"
          [class.d-none]="!isChecked">
        <ng-container *ngTemplateOutlet="DeleteFolder;"></ng-container>
      </button>

    </ng-container>

    <ng-container *ngIf="isTemplate">
      <ng-container *ngTemplateOutlet="returnBtnForMobileTemplate"></ng-container>
    </ng-container>




    <!-- btn for Desktop -->
    <div class="d-none-sm hide-text-for-ipad"
        *ngIf="downloadButton && !isBroadcast && !isTemplate">
      <button class="btn btn-outline mt-1 ml-2 custom-height">
        <ng-container *ngTemplateOutlet="downloadFolder;"></ng-container>
      </button>
      <button class="btn btn-outline mt-1 ml-2 custom-height"
          *ngIf="canReturn && isChecked"
          (click)="onReturnMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        <!-- <svg-icon src="assets/images/icons/return.svg"
            class="icon"
            [svgStyle]="{'width.px': 15, 'height.px': 15}"
            [style.--c]="themeList?.header_table_color"></svg-icon> -->
        {{"MEMOS.return" | translate}}
      </button>
      <button class="btn btn-outline-danger mt-1 ml-2 custom-height"
          *ngIf="!canDeleted && isChecked && !isFolder"
          (click)="canReturn ? onPermanentlyDelete() : onTrashMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        <!-- <svg-icon src="assets/images/icons/trash.svg"
            class="icon delete-btn"
            [svgStyle]="{'width.px': 15, 'height.px': 15, 'fill': '#dc3545',
            'marginBottom.px': 3, 'stroke': '#f8a2ab'}"
            [style.--c]="themeList?.header_table_color"></svg-icon> -->
        {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
        ("MEMOS.Delete Document-2" | translate) }}
      </button>
      <ng-container *ngIf="closeNewFeature">
        <button class="btn mt-1 ml-2 custom-height"
            [style.border-color]="'#dc3545'"
            [style.color]="'#dc3545'"
            *ngIf="isChecked && isFolder"
            [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
          <ng-container *ngTemplateOutlet="DeleteFolder;"></ng-container>
        </button>

        <button *ngIf="!isTemplate"
            type="button"
            appSetTheme
            [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}"
            class="btn mt-1 ml-2 custom-height "
            [class.d-none]="!isChecked">
          <ng-container *ngTemplateOutlet="moveFolderTemplate;"></ng-container>
        </button>
        <ng-container *appFeatureFlag="'open_feature_sprint_2'">
          <button *ngIf="!isTemplate"
              type="button"
              appSetTheme
              [ngStyle]="isCheckedDraft ? {'cursor': 'pointer'} : {'cursor' : 'default'}"
              class="btn mt-1 ml-2 custom-height "
              (click)="clickTransferDocument()"
              [class.d-none]="!isCheckedDraft">
            {{"DOCUMENT.Transfer Document"|translate}}
          </button>
        </ng-container>
      </ng-container>
    </div>

    <!-- for Template -->
    <div class="d-none-sm hide-text-for-ipad"
        *ngIf="isTemplate">
      <button class="btn btn-outline mt-1 ml-2 custom-height"
          *ngIf="canReturn && isChecked"
          (click)="onReturnMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        <svg-icon src="assets/images/icons/return.svg"
            class="icon"
            [svgStyle]="{'width.px': 15, 'height.px': 15}"
            [style.--c]="themeList?.header_table_color"></svg-icon>
        {{"MEMOS.return" | translate}}
      </button>
      <button class="btn custom-height btn-outline-danger mt-1 ml-2"
          *ngIf="!canDeleted && isChecked"
          (click)="onTrashTemplate()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        <svg-icon src="assets/images/icons/trash.svg"
            class="icon delete-btn"
            [svgStyle]="{'width.px': 15, 'height.px': 15, 'fill': '#dc3545',
            'marginBottom.px': 3, 'stroke': '#f8a2ab'}"
            [style.--c]="themeList?.header_table_color"></svg-icon>
        {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
        ("TEMPLATE.Delete Template"|translate) }}
      </button>
    </div>

    <!-- for Broadcast -->
    <div class="d-none-sm"
        *ngIf="isBroadcast">
      <button class="btn btn-outline mt-1 ml-md-2 ml-lg-3 custom-height"
          *ngIf="alwaysShowDownloadAll || checkStatusApproved"
          (click)="onDownloadAllBroadcastMemo()">
        <svg-icon src="assets/images/icon_download2.svg"
            class="icon"
            [svgStyle]="{'width.px': 15, 'height.px': 15}"
            [style.--c]="themeList?.header_table_color"></svg-icon>
        <span class="hide-text-for-ipad">
          {{"BROADCAST.Download all items" | translate}}
        </span>
      </button>

      <div ngbDropdown
          class="d-inline-block"
          *ngIf="listTypeResendBtn.length > 0">
        <button class="btn btn-custom-border btn-outline mt-1 ml-md-2 ml-lg-3 custom-height"
            id="dropdownBasic1"
            ngbDropdownToggle
            (click)="showListMenu = !showListMenu">
          <span *ngIf="listTypeResendBtn[0].status === 'approved'">{{"BROADCAST.btn-download"|translate}}</span>
          <span *ngIf="listTypeResendBtn[0].status === 'pending'">{{"BROADCAST.btn-resend"|translate}}</span>
          <span *ngIf="listTypeResendBtn[0].status === 'terminated'">{{"BROADCAST.btn-resend-again"|translate}}</span>
          <i class="far fa-angle-down ml-2"
              *ngIf="!showListMenu"></i>
          <i class="far fa-angle-up ml-2"
              *ngIf="showListMenu"></i>
        </button>
        <div ngbDropdownMenu
            container="body"
            class="w-100"
            aria-labelledby="dropdownBasic1">
          <ng-container *ngFor="let item of listTypeResendBtn;let i = index;">
            <button ngbDropdownItem
                [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
                class="border-bottom py-2"
                *ngIf="item.status === 'pending'"
                (click)="openModalResend(resent, 'notification');showListMenu = false">
              {{"BROADCAST.btn-resend"|translate}}
            </button>
            <button ngbDropdownItem
                [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
                class="py-2"
                *ngIf="item.status === 'terminated'"
                (click)="openModalResend(resent, 'rejected');showListMenu = false">
              {{"BROADCAST.btn-resend-again"|translate}}
            </button>
            <button ngbDropdownItem
                [ngClass]="listTypeResendBtn.length === i+1 ? 'btn-menu-line-none': ''"
                class="py-2"
                *ngIf="item.status === 'approved'"
                (click)="onDownloadMemo();showListMenu = false">
              {{"BROADCAST.btn-download"|translate}}
            </button>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div class="mr-2 mt-1 word-page">{{"UPLOAD.PAGE" | translate}}</div>
  <ngb-pagination *ngIf="count"
      [boundaryLinks]="boundaryLinks"
      [collectionSize]="count"
      [ellipses]="ellipses"
      [rotate]="rotate"
      [(page)]="page"
      aria-label="count"
      [pageSize]="pageSize"
      [maxSize]="isMobile ? (noneSize?2:1) : maxSize"
      (pageChange)="changePageEmit()"
      style="margin-top: 0.25rem;">
    <ng-template ngbPaginationPrevious>
      <svg-icon src="assets/images/prev.svg"></svg-icon>
    </ng-template>
    <ng-template ngbPaginationNext>
      <svg-icon src="assets/images/next.svg"></svg-icon>
    </ng-template>
  </ngb-pagination>
</div>

<ng-template #selectDateDownloadModal
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{"MEMOS.CONFIRM" | translate}}</h4>
    <hr>
  </div>

  <div class="modal-body text-center">
    <h4>{{"MEMOS.CONFIRM-DOWNLOAD-CSV" | translate}}</h4>
  </div>

  <div class="modal-footer">
    <button class="btn eh-button btn-outline p-btn"
        [style.--c]="themeList?.header_table_color"
        (click)="modal.dismiss('Cross click')"
        type="button">
      <span class="mr-auto">{{ "APPROVAL.CANCEL" | translate }}</span>
    </button>

    <button class="btn eh-button p-btn"
        (click)="generateReportExcel()"
        [ladda]="isLoadingCsv"
        type="button"
        appSetTheme>
      <span class="mr-auto">{{ "LOG.DOWNLOAD-CSV" | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #resent
    let-modal>
  <div class="modal-body p-3">
    <div class="modal-header">
      <h4 class="modal-title">
        <span *ngIf="typeResend === 'rejected'">{{"BROADCAST.btn-resend-again"|translate}}</span>
        <span *ngIf="typeResend === 'notification'">{{"BROADCAST.btn-resend"|translate}}</span>
      </h4>
      <button type="button"
          class="close text-black"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>

  <div class="modal-body text-center"
      *ngIf="typeResend === 'notification'">
    {{"BROADCAST.CONTENT-RESEND-NOTIFICATION"|translate}}
  </div>
  <div class="modal-body text-center"
      *ngIf="typeResend === 'rejected'">
    {{"BROADCAST.CONTENT-RESEND-REJECTED"|translate}}
  </div>

  <div class="col-12 text-center mb-5">
    <button class="btn btn-outline-primary p-btn"
        (click)="modal.dismiss('Cross click')">
      <span class="mr-auto">{{ "APPROVAL.CANCEL" | translate }}</span>
    </button>

    <button class="btn btn-primary p-btn ml-3"
        type="button"
        appSetTheme
        (click)="confirmResend()">
      <span class="mr-auto">{{ "MEMOS.CONFIRM" | translate }}</span>
    </button>
  </div>
</ng-template>


<ng-template #moveFolderTemplate>
  <div ngbDropdown
      (clickOutside)="clickOutside($event,myDropdown)"
      (window:resize)="onResize($event,myDropdown)"
      #myDropdown="ngbDropdown"
      container="body"
      [autoClose]="false"
      class="d-inline-block custom-dropdown">

    <div class=""
        appSetTheme
        id="dropdownBasic1"
        (click)="openMoveDropdown()"
        ngbDropdownToggle>
      <span class="pl-1">
        <span><svg-icon src="assets/svg/icon-move-folder.svg"
          class="icon d-md-none"
          [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -3, 'margin-left.px': -5}"
          [style.--c]="themeList?.header_table_color"></svg-icon></span>
        <!-- <svg-icon [src]="'assets/svg/icon-move-folder.svg'"
            [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -3, 'margin-left.px': -5}"></svg-icon> -->
        <span class="d-none-sm">
          {{'FOLDER.Move to'|translate}}
        </span>
      </span>
      <!-- <svg-icon [src]="'assets/svg/icon-arrow-down.svg'"
                [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -1}"></svg-icon> -->
    </div>
    <div ngbDropdownMenu
        class="pb-0"
        aria-labelledby="dropdownBasic1">
      <!-- <button ngbDropdownItem
          (click)="openMoveFolder(memo)" 
          class="border-bottom py-2"> -->
      <!-- {{"FOLDER.Move to"|translate}} -->
      <button ngbDropdownItem
          class="move-folder px-2 item-folder">
        <div>
          <div class="item-folder position-absolute icon-search">
            <svg-icon [src]="'assets/svg/icon-search.svg'"
                class="item-folder"
                [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': 4}"></svg-icon>
          </div>
          <input type="text"
              [(ngModel)]="searchFolder"
              [placeholder]="'DASHBOARD.SEARCH'|translate"
              class="item-folder form-control input-custom-search-folder">
          <div class="max-height-folder"
              #scrollMe>
            <div class="d-flex align-items-center item-folder create-folder-mobile"
                *ngIf="isClickFolder">
            </div>
            <ng-container *ngIf="folderList?.length > 0">
              <div class="py-2 folder-name item-folder pl-1"
                  *ngFor="let folder of filterItems()">
                <div class="w-100 h-100"
                    (click)="openMoveFolderModal(folder, myDropdown);">
                  {{folder.name}}
                </div>

              </div>
            </ng-container>
            <div class="d-flex align-items-center item-folder hide-create-folder-mobile"
                *ngIf="isClickFolder && !isTablet">
              <input type="text"
                  (keydown.enter)="enterCreateFolder($event, myDropdown);"
                  #inputFolderName
                  [(ngModel)]="folderName"
                  class="item-folder form-control input-folder-name pl-1">
              <svg-icon class="item-folder"
                  [svgClass]="'item-folder'"
                  src="assets/svg/check.svg"
                  (click)="enterCreateFolder(folderName, myDropdown)"
                  [svgStyle]="{ 'height.px': 20, 'width.px': 20}">
              </svg-icon>
            </div>
          </div>
        </div>

        <div class="create-folder p-2 item-folder"
            [class.disabled]="isClickFolder"
            [style.padding]="'0.2rem !important'"
            (click)="createFolder(createFolderModal)">
          {{"FOLDER.CREATE-FOLDER"|translate}}
        </div>
      </button>
      <!-- </button> -->
    </div>
  </div>
</ng-template>


<ng-template #DeleteFolder>
  <div ngbDropdown
      (clickOutside)="clickOutside($event,myDropdown)"
      #myDropdown="ngbDropdown"
      container="body"
      [autoClose]="true"
      class="d-inline-block w-100 custom-dropdown">
    <div class=""
        appSetTheme
        id="dropdownBasic1"
        ngbDropdownToggle>
      <span class="">
        <!-- <svg-icon src="assets/images/icons/trash.svg"
            class="icon delete-btn"
            [svgStyle]="{'width.px': 15, 'height.px': 15, 'fill': '#dc3545',
        'marginBottom.px': 3, 'stroke': '#f8a2ab'}"
            [style.--c]="themeList?.header_table_color"></svg-icon> -->
        <span class="d-none-sm">
          {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
          ("FOLDER.Delete" | translate) }}
        </span>

        <svg-icon [src]="'assets/svg/icon-arrow-down.svg'"
            class="d-none-sm"
            [svgStyle]="{'fill':'#dc3545','width.px': 20, 'height.px': 20, 'margin-top.px': -1}"></svg-icon>
      </span>
      <!-- <svg-icon [src]="'assets/svg/icon-arrow-down.svg'"
                          [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -1}"></svg-icon> -->
    </div>
    <div ngbDropdownMenu
        class="py-0"
        aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
          *ngIf="!canDeleted"
          class="item-folder py-2"
          (click)="canReturn ? onPermanentlyDelete() : onTrashMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
        ("MEMOS.Delete Document-2" | translate)}}
      </button>

      <button ngbDropdownItem
          class="item-folder py-2 border-top"
          [ngClass]="{ 'border-top-0': canDeleted }"
          (click)="openDeleteFolderModal()"
          [disabled]="isLoading">
        <div>
          {{ 'FOLDER.Delete from folder' |translate }}
        </div>
      </button>

    </div>
  </div>
</ng-template>

<ng-template #downloadFolder>
  <div ngbDropdown
      (clickOutside)="clickOutside($event,myDropdown)"
      #myDropdown="ngbDropdown"
      container="body"
      [autoClose]="true"
      class="d-inline-block w-100 custom-dropdown">
    <div appSetTheme
        id="dropdownBasic1"
        ngbDropdownToggle>
      <span class="pl-1">
        <!-- <svg-icon [src]="'assets/images/icon_download2.svg'"
            [style.--c]="themeList?.header_table_color"
            class="icon"
            [svgStyle]="{'width.px': 15, 'height.px': 15, 'margin-right.px': 3}"></svg-icon> -->
        {{'MEMOS.DOWNLOAD'|translate}}</span>
      <svg-icon [src]="'assets/svg/icon-arrow-down.svg'"
          [style.--c]="themeList?.header_table_color"
          class="icon ml-2"
          [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -1}"></svg-icon>
    </div>
    <div ngbDropdownMenu
        class="py-0"
        aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
          class="item-folder py-2"
          (click)="openModal(selectDateDownloadModal)">
        {{textBtn? (textBtn|translate):("LOG.DOWNLOAD-CSV" | translate)}}
      </button>
      <button ngbDropdownItem
          class="item-folder py-2 border-top"
          *ngIf="!isTemplate"
          [class.d-none]="!isChecked"
          (click)="onDownloadMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        {{"MEMOS.Download Document" | translate}}
      </button>
    </div>
  </div>
</ng-template>


<ng-template #returnBtnForMobile>
  <div ngbDropdown
      #myDropdown="ngbDropdown"
      container="body"
      *ngIf="(isChecked && canReturn) || (!canDeleted && isChecked && !isFolder)"
      [autoClose]="true"
      class="d-inline-block w-100 custom-dropdown d-md-none">
    <button appSetTheme
        id="dropdownBasic1"
        class="btn btn-outline mt-1 ml-md-2 ml-lg-3 custom-height ml-2"
        [style.border-color]="'#dc3545'"
        ngbDropdownToggle>
      <svg-icon src="assets/images/icons/trash.svg"
          class="icon delete-btn"
          [svgStyle]="{'width.px': 15, 'height.px': 15, 'fill': '#dc3545',
        'marginBottom.px': 3, 'stroke': '#f8a2ab'}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
    </button>
    <div ngbDropdownMenu
        class="py-0"
        aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
          *ngIf="canReturn && isChecked"
          (click)="onReturnMemo()"
          class="item-folder py-2">
        {{"MEMOS.return" | translate}}
      </button>
      <button ngbDropdownItem
          *ngIf="!canDeleted && isChecked && !isFolder"
          [ngClass]="!canReturn? 'border-top-0' : ''"
          class="item-folder py-2 border-top"
          (click)="canReturn ? onPermanentlyDelete() : onTrashMemo()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
        ("MEMOS.Delete Document-2" | translate) }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #returnBtnForMobileTemplate>
  <div ngbDropdown
      #myDropdown="ngbDropdown"
      container="body"
      *ngIf="(canReturn && isChecked) || (!canDeleted && isChecked)"
      [autoClose]="true"
      class="d-inline-block w-100 custom-dropdown d-md-none">
    <button appSetTheme
        id="dropdownBasic1"
        class="btn btn-outline mt-1 ml-md-2 ml-lg-3 custom-height ml-1"
        [style.border-color]="'#dc3545'"
        ngbDropdownToggle>
      <svg-icon src="assets/images/icons/trash.svg"
          class="icon delete-btn"
          [svgStyle]="{'width.px': 15, 'height.px': 15, 'fill': '#dc3545',
        'marginBottom.px': 3, 'stroke': '#f8a2ab'}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
    </button>
    <div ngbDropdownMenu
        class="py-0"
        aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
          *ngIf="canReturn && isChecked"
          (click)="onReturnMemo()"
          class="item-folder py-2">
        {{"MEMOS.return" | translate}}
      </button>
      <button ngbDropdownItem
          *ngIf="!canDeleted && isChecked"
          class="item-folder py-2 border-top"
          [ngClass]="!canReturn? 'border-top-0' : ''"
          (click)="onTrashTemplate()"
          [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
        {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
        ("TEMPLATE.Delete Template"|translate) }}
      </button>
    </div>

  </div>
</ng-template>

<ng-template #btnForIpadPortrait>
  <div class="show-btn-ipad"
      *ngIf="!isBroadcast"
      [style.--c]="themeList?.header_table_color"
      [style.--t]="themeList?.text_header_table_color">
    <button class="btn btn-outline ml-md-2 ml-lg-3 mt-1"
        *ngIf="downloadButton && !isBroadcast && !isTemplate && !isChecked"
        (click)="openModal(selectDateDownloadModal)">
      <svg-icon src="assets/images/icon_download2.svg"
          class="icon"
          [svgStyle]="{'width.px': 15, 'height.px': 15}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
      {{textBtn? (textBtn|translate):("LOG.DOWNLOAD-CSV" | translate)}}
    </button>
    <div ngbDropdown
        *ngIf="isChecked"
        class="d-inline-block"
        #myDropdown="ngbDropdown"
        container="body"
        (click)="showListMenu = !showListMenu"
        [autoClose]="true">
      <button class="btn btn-custom-border btn-outline mt-1 ml-md-2 ml-lg-3 custom-height"
          id="dropdownBasic1"
          ngbDropdownToggle>
        <span>
          {{countCheck}} {{"COMMON.ITEMS"|translate}}</span>
        <i class="far fa-angle-down ml-2"
            *ngIf="!showListMenu"></i>
        <i class="far fa-angle-up ml-2"
            *ngIf="showListMenu"></i>
      </button>
      <ng-container *ngIf="!isTemplate">
        <div ngbDropdownMenu
            class="w-100"
            aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem
              [ngClass]="!isChecked ? '': 'border-bottom'"
              class="py-2"
              (click)="openModal(selectDateDownloadModal)">
            {{textBtn? (textBtn|translate):("LOG.DOWNLOAD-CSV" | translate)}}
          </button>

          <button ngbDropdownItem
              [class.d-none]="!isChecked"
              class="py-2"
              (click)="onDownloadMemo()">
            {{"MEMOS.Download Document" | translate}}
          </button>

          <ng-container *ngIf="isFolder">
            <button ngbDropdownItem
                *ngIf="!canDeleted"
                class="item-folder py-2 border-top"
                (click)="canReturn ? onPermanentlyDelete() : onTrashMemo()"
                [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
              {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
              ("MEMOS.Delete Document-2" | translate)}}
            </button>

            <button ngbDropdownItem
                class="item-folder py-2 border-top"
                (click)="openDeleteFolderModal()"
                [disabled]="isLoading">
              <div>
                {{ 'FOLDER.Delete from folder' |translate }}
              </div>
            </button>
          </ng-container>

          <ng-container *ngIf="!isFolder">
            <button ngbDropdownItem
                *ngIf="canReturn && isChecked"
                (click)="onReturnMemo()"
                class="item-folder py-2 border-top">
              {{"MEMOS.return" | translate}}
            </button>
            <button ngbDropdownItem
                *ngIf="!canDeleted && isChecked && !isFolder"
                class="item-folder py-2 border-top"
                (click)="canReturn ? onPermanentlyDelete() : onTrashMemo()"
                [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
              {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
              ("MEMOS.Delete Document-2" | translate) }}
            </button>
          </ng-container>

        </div>
      </ng-container>

      <ng-container *ngIf="isTemplate">
        <div ngbDropdownMenu
            class="w-100"
            aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem
              *ngIf="canReturn && isChecked"
              (click)="onReturnMemo()"
              class="item-folder py-2">
            {{"MEMOS.return" | translate}}
          </button>
          <button ngbDropdownItem
              *ngIf="!canDeleted && isChecked"
              class="item-folder py-2 border-top"
              (click)="onTrashTemplate()"
              [ngStyle]="isChecked ? {'cursor': 'pointer'} : {'cursor' : 'default'}">
            {{ canReturn ? ("MEMOS.Permanently Delete" | translate) :
            ("TEMPLATE.Delete Template"|translate) }}
          </button>
        </div>
      </ng-container>

    </div>
  </div>
</ng-template>

<ng-template #createFolderModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header item-folder">
    <h4 class="title item-folder">{{ 'FOLDER.CREATE-FOLDER'| translate }}</h4>
    <button type="button"
        class="close item-folder"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4 custom-box text-gray item-folder">
    <ng-container>
      <div class="col-12 p-0 item-folder">
        <label class="item-folder">{{ "FOLDER.FOLDER-NAME"|translate }}</label>
        <input type="text"
            class="form-control item-folder"
            placeholder="Folder"
            [(ngModel)]="folderName">
      </div>

    </ng-container>
    <div class="justify-content-end mt-5 btn-container text-right item-folder"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <button type="button"
          class="btn btn-outline w-auto"
          (click)="close()">{{ "APPROVAL.CANCEL" | translate }}
      </button>
      <button type="button"
          class="btn ml-2 item-folder"
          appSetTheme
          (click)="enterCreateFolder()">{{ 'MEMOS.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</ng-template>